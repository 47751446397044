import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import IconZoom from '@/assets/img/ico_contents_zoom@2x.png';
import FileContainer from '@/components/AttachmentBox';
import BoardPrevNext, { PrevNextArticle } from '@/components/BoardPrevNext';
import Button from '@/components/Button';
import NewsThumbCard from '@/components/Card/NewsThumbCard';
import CKEditorContent from '@/components/CKEditorContent';
import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import { News } from '@/components/News/types';
import { Category, KeysSet } from '@/components/News/WordSet';
import { H2, Tit } from '@/components/Titles';
import {
  BOARD_KIND_FIELDTRIP,
  BOARD_KIND_NEWS,
  BOARD_KIND_PUBLIC,
  BOARD_KIND_STORY,
  BOARD_KIND_INTERVIEW,
} from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

export const SectionContainer = styled.section`
  padding-top: 96px;

  ${breakpoint(`tablet`)} {
    padding-top: 48px;
    padding-bottom: 0;
  }
`;

const DetailContainer = styled.div``;
const BoardHeader = styled.dt`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #e5e6e8;
  padding-bottom: 32px;

  ${Tit} {
    width: 100%;
    position: relative;
    line-height: 40px;
    letter-spacing: -0.8px;

    .cate {
      display: inline-block;
      width: 103px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      background: #1cabe2;
      margin-right: 16px;
    }
  }

  ${breakpoint(`mobile`)} {
    display: block;

    ${Tit} {
      width: 100%;
      padding: 0;
      font-size: 18px;

      span {
        display: block;
      }

      .cate {
        display: inline-block;
        width: auto;
        padding: 0 30px;
        position: static;
        margin-bottom: 12px;
      }
    }

    .date {
      display: block;
      text-align: right;
      margin-top: 8px;
      padding: 0;
    }
  }
`;

const BoardBody = styled.dd`
  display: block;
  padding-top: 30px;

  .tag-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 64px;
    margin-bottom: 32px;

    a + a {
      margin-left: 8px;
    }

    ${Button} {
      height: 26px;
      line-height: 24px;
      font-size: 14px;
      padding: 0 5px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-weight: normal;

      &:last-child {
        margin-right: 0;
      }
    }

    ${breakpoint(`tablet`)} {
      margin-top: 48px;
      margin-bottom: 24px;
    }
  }
`;
const ArticleContentZoom = styled.div`
  text-align: right;
  padding: 32px 0;

  button {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-image: url(${IconZoom});
    background-size: 79px 40px;
    background-repeat: no-repeat;
  }
`;
const ZoomUp = styled.button`
  background-position: right top;
  margin-left: -1px;
`;
const ZoomDown = styled.button`
  background-position: left top;
`;
const ArticleContent = styled(CKEditorContent)`
  img {
    margin: 0 auto;
    max-width: 100%;
  }

  ${breakpoint(800)} {
    img {
      max-width: 100%;
      height: auto !important;
    }
  }

  // IE blut css 보정
  ul {
    list-style-type: disc;
  }
`;

const RelatedContainer = styled.dl`
  padding-top: 64px;
  margin-top: 64px;
  border-top: 2px solid #e5e6e8;

  ${breakpoint(`tablet`)} {
    padding-top: 48px;
    margin-top: 24px;
  }

  dd {
    padding-top: 32px;

    .swiper-container {
      margin-left: -16px;
      margin-right: -16px;
    }

    .swiper-slide {
      width: 25%;
      padding: 0 16px;
      margin: 0;

      ${breakpoint(`tablet`)} {
        width: 50%;
      }
    }

    .img-box {
      padding-top: 100%;
    }

    p {
      padding-left: 0;
      padding-right: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const BtnWrap = styled.div`
  text-align: center;
  padding: 64px 0;

  ${breakpoint(`tablet`)} {
    padding: 48px 0;
  }
`;

const H2Title = styled.h2`
  display: inline-block;
  line-height: 1.56;
  letter-spacing: -0.8px;
  font-size: 24px;
  ${breakpoint(`tablet`)} {
    font-size: 18px;
  }
`;

const NewsDetail: FC<PageProps> = ({
  location,
  params: { boardIndexNumber },
}) => {
  const qs = queryString.parse(location.search);
  const keyword = qs.keyword as string;
  const [fontSize, setFontSize] = useState(16);
  const [prevItem, setPrevItem] = useState<PrevNextArticle | undefined>();
  const [nextItem, setNextItem] = useState<PrevNextArticle | undefined>();
  const [news, setNews] = useState<News>();

  const fontSizeUp = () => setFontSize(fontSize + 1);
  const fontSizeDown = () => setFontSize(fontSize - 1);

  // 소식 상세 로드
  const loadDetail = useCallback(async () => {
    let boardCategoryCode;
    if (location.state?.boardCategoryCode) {
      boardCategoryCode = location.state.boardCategoryCode;
    } else {
      try {
        const { data } = await BoardCommonControllerService.foListAllUsingGet({
          boardCategoryCode: [
            BOARD_KIND_NEWS,
            BOARD_KIND_PUBLIC,
            BOARD_KIND_STORY,
            BOARD_KIND_FIELDTRIP,
            BOARD_KIND_INTERVIEW,
          ],
          boardIndexNumber,
        });
        if (data.length === 0) {
          navigate('/what-we-do/news');
          return;
        }
        boardCategoryCode = data[0].boardCategoryCode;
      } catch (e) {
        console.error(e);
      }
    }

    if (boardCategoryCode) {
      try {
        const { data } = await BoardCommonControllerService.foDetailUsingGet({
          boardCategoryCode,
          boardIndexNumber,
        });
        const newsData = data as any;
        if (newsData.statusCode !== '1') {
          navigate('/what-we-do/news');
          return;
        }
        setNews(newsData);
        setPrevItem({
          boardIndexNumber: newsData.prevBoardIndexNumber,
          subject: newsData.prevSubject,
          firstRegisterDate: newsData.prevRegDate,
          boardCategoryCode: newsData.boardCategoryCode,
        });
        setNextItem({
          boardIndexNumber: newsData.nextBoardIndexNumber,
          subject: newsData.nextSubject,
          firstRegisterDate: newsData.nextRegDate,
          boardCategoryCode: newsData.boardCategoryCode,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [location.state, boardIndexNumber]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (targetKeyword?: string) => {
      const { boardCategoryCode }: any = location.state;
      const {
        resultCode,
        data: _data,
      } = await BoardCommonControllerService.foDetailUsingGet({
        boardCategoryCode,
        boardIndexNumber,
        keyword: targetKeyword,
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        setPrevItem({
          boardIndexNumber: newData.prevBoardIndexNumber,
          subject: newData.prevSubject,
          firstRegisterDate: newData.prevRegDate,
          boardCategoryCode: newsData.boardCategoryCode,
        });
        setNextItem({
          boardIndexNumber: newData.nextBoardIndexNumber,
          subject: newData.nextSubject,
          firstRegisterDate: newData.nextRegDate,
          boardCategoryCode: newsData.boardCategoryCode,
        });
      }
    },
    [location.state, boardIndexNumber],
  );

  /* 조회수 증가 */
  const popupStore = usePopupStore();
  const updateViewCnt = useCallback(async () => {
    const {
      resultCode,
      resultMessage,
    } = await BoardCommonControllerService.viewCntUsingPost({
      boardIndexNumber: boardIndexNumber.toString(),
    });

    if (resultCode !== `success`) {
      if (resultMessage !== undefined) {
        popupStore.show(resultMessage);
      } else {
        popupStore.show(
          `소식: ${boardIndexNumber} 조회수 증가를 실패했습니다.`,
        );
      }
    }
  }, []);

  // keyword 변경되면 업데이트
  useEffect(() => {
    if (keyword) {
      loadPage(keyword as string);
    }
    if (news) {
      updateViewCnt();
    }
  }, [keyword, loadPage, news, updateViewCnt]);

  if (!news) {
    return null;
  }

  return (
    <LayoutWithTitle
      location={location}
      title="소식"
      description="for every child, news"
    >
      <div>
        <SectionContainer className="by-sub-main-layout">
          <h2 className="a11y">게시글 상세</h2>
          <Container>
            <article>
              <DetailContainer>
                <BoardHeader>
                  <Tit size="s3-1">
                    {Category(`${news.boardCategoryCode}`) !== `` ? (
                      <span className="cate">
                        {Category(`${news.boardCategoryCode}`)}
                      </span>
                    ) : null}
                    <H2Title>{news.subject}</H2Title>
                  </Tit>
                  <p className="date">
                    {dayjs(news.dispStartDate, `YYYY.MM.DD`).format(
                      `YYYY.MM.DD`,
                    )}
                  </p>
                </BoardHeader>
                <ArticleContentZoom>
                  <ZoomDown type="button" onClick={fontSizeDown}>
                    <span className="for-a11y">축소</span>
                  </ZoomDown>
                  <ZoomUp type="button" onClick={fontSizeUp}>
                    <span className="for-a11y">확대</span>
                  </ZoomUp>
                </ArticleContentZoom>
                <BoardBody>
                  <ArticleContent
                    style={{ fontSize }}
                    dangerouslySetInnerHTML={{
                      __html:
                        news.contents &&
                        news.contents.replace(/href/g, `target='_blank' href`),
                    }}
                  />
                  {news.relCategoryCode !== null && (
                    <div className="tag-flex">
                      {news.relCategoryCode !== null &&
                        KeysSet(`${news.relCategoryCode}`).map((key) => (
                          <LinkSafe
                            to={`/what-we-do/news?category=${key.tag}#newsSection`}
                            key={key.tag}
                          >
                            <Button outline key={key.tag} className="tag">
                              #{key.tagName}
                            </Button>
                          </LinkSafe>
                        ))}
                    </div>
                  )}
                  {news.arrAttFile !== undefined &&
                    news.arrAttFile.length > 0 && (
                      <FileContainer files={news.arrAttFile} />
                    )}
                </BoardBody>
              </DetailContainer>
              {news.arrRelBoard !== undefined && news.arrRelBoard.length > 0 && (
                <RelatedContainer
                  css={`
                    margin-top: 120px;
                  `}
                >
                  <dt>
                    <H2>관련 글</H2>
                  </dt>
                  <dd>
                    <NewsThumbCard
                      boards={news.arrRelBoard}
                      baseUri="/what-we-do/news"
                      queryString={location.search}
                    />
                  </dd>
                </RelatedContainer>
              )}
              <BtnWrap>
                <Button
                  color="grey"
                  size="sm"
                  width={256}
                  onClick={() => navigate(`/what-we-do/news${location.search}`)}
                >
                  목록
                </Button>
              </BtnWrap>
              <BoardPrevNext
                prev={prevItem}
                next={nextItem}
                baseUri="/what-we-do/news"
                queryString={location.search}
              />
            </article>
          </Container>
        </SectionContainer>
      </div>
    </LayoutWithTitle>
  );
};

export default NewsDetail;
